var Handlebars = require("../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!doctype html>\r\n<html lang=\"en-US\">\r\n<head>\r\n    <meta http-equiv=\"Content-Type\" content=\"text/html; charset=utf-8\">\r\n    <title>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"htmlWebpackPlugin") : depth0)) != null ? lookupProperty(stack1,"options") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</title>\r\n\r\n    <link rel=\"apple-touch-icon\" sizes=\"180x180\" href=\"/apple-touch-icon.png\">\r\n    <link rel=\"icon\" type=\"image/png\" sizes=\"32x32\" href=\"/favicon-32x32.png\">\r\n    <link rel=\"icon\" type=\"image/png\" sizes=\"16x16\" href=\"/favicon-16x16.png\">\r\n    <link rel=\"manifest\" href=\"/site.webmanifest\">\r\n    <link rel=\"mask-icon\" href=\"/safari-pinned-tab.svg\" color=\"#790dbf\">\r\n    <meta name=\"msapplication-TileColor\" content=\"#ffffff\">\r\n    <meta name=\"theme-color\" content=\"#ff0000\">\r\n</head>\r\n<body>\r\n"
    + ((stack1 = container.invokePartial(require("./partials/menu.hbs"),depth0,{"name":"partials/menu","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(require("./partials/hero.hbs"),depth0,{"name":"partials/hero","hash":{"text":"<h1 class=\"display-1\" data-aos=\"fade-up\">Wysublimowane <br> <strong>rozwiązania IT.</strong></h1>","image":"images/bg-2.jpg","fullscreen":true},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(require("./partials/services.hbs"),depth0,{"name":"partials/services","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./partials/technologies.hbs"),depth0,{"name":"partials/technologies","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./partials/about.hbs"),depth0,{"name":"partials/about","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./partials/how_we_work.hbs"),depth0,{"name":"partials/how_we_work","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./partials/products.hbs"),depth0,{"name":"partials/products","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./partials/footer.hbs"),depth0,{"name":"partials/footer","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</body>\r\n</html>\r\n";
},"usePartial":true,"useData":true});